import React, { Component, Suspense } from "react";
import { Provider } from "mobx-react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
// Styles
// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Flag Icons Set
// import "flag-icon-css/css/flag-icon.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Toastify
import 'react-toastify/dist/ReactToastify.css';
// Import Main styles for this application
import "./scss/style.css";
import "./scss/custom-style.css";
import "./scss/customImage.css";
import "./scss/loading.css";
import "./scss/chat-style.css";
// Containers
import "react-datepicker/dist/react-datepicker.css";

import stores from "./stores";
import ScrollToTop from './components/ScrollToTopComponent';

const AppSnackbar = React.lazy(() => import("./components/AppSnackbar"));

const Login = React.lazy(() => import("./views/Pages/Login/Login"));
const PrivacyPolicy = React.lazy(() => import("./views/Pages/PrivacyPolicy/PrivacyPolicy"));
const Page404 = React.lazy(() => import("./views/Pages/Page404/Page404"));
const Page500 = React.lazy(() => import("./views/Pages/Page500/Page500"));
const AnivView = React.lazy(() => import("./views/Pages/AnivView/AnivView"));
const InvoiceUser = React.lazy(() => import("./views/Pages/InvoiceUser/InvoiceUser"));
const DeliveryNote = React.lazy(() => import("./views/Pages/DeliveryNote/DeliveryNote"));

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout/DefaultLayout"));

// import { renderRoutes } from 'react-router-config';

const version = '2.13.0.0';
class App extends Component {
  componentDidMount() {
    console.log(`Version: ${version}`);
  }
  render() {
    return (
      <Provider {...stores}>
        <React.Fragment>
          <Suspense fallback={<div></div>}>
            <AppSnackbar />
          </Suspense>
          <Suspense fallback={<div></div>}>
            <BrowserRouter>
              <React.Fragment>
                <ScrollToTop />
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props} />}/>
                  <Route exact path="/privacy-policy" name="Privacy-Policy Page" render={props => <PrivacyPolicy {...props} />}/>
                  <Route exact path="/anivview/:id" name="Aniv View" render={props => <AnivView {...props} />} />
                  <Route exact path={`/:region/invoiceuser/:id/:download`} name="Invoice Order" render={props => <InvoiceUser {...props} />} />
                  <Route exact path={`/:region/deliverynote/:id/:download`} name="Deliver Note" render={props => <DeliveryNote {...props} />} />
                  <Route exact path={`/:region/invoiceuser/:id`} name="Invoice Orders" render={props => <InvoiceUser {...props} />} />
                  <Route exact path={`/:region/deliverynote/:id`} name="Delivery Note" render={props => <DeliveryNote {...props} />} />
                  {/* <Route exact path={`/invoiceuser/:id/:download`} name="Invoice Order" component={InvoiceUser} />
                  <Route exact path={`/deliverynote/:id/:download`} name="Deliver Note" component={DeliveryNote} />
                  <Route exact path={`/invoiceuser/:id`} name="Invoice Orders" component={InvoiceUser} />
                  <Route exact path={`/deliverynote/:id`} name="Delivery Note" component={DeliveryNote} /> */}
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                  <Route path="/" name="Home" render={routeProps => <DefaultLayout version={version} {...routeProps} />}/>
                </Switch>
              </React.Fragment>
            </BrowserRouter>

          </Suspense>
        </React.Fragment>
      </Provider>
    );
  }
}

export default App;
